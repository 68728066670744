
import { Route, Routes } from 'react-router-dom';
import './App.css';

import Home from './Components/Home/Home';

function App() {
  return (
    <div className="App" style={{ color: 'var(--primaryColor)', fontFamily: 'var(--primaryFont)', fontSize: 'var(--Text)' }}>


      <Routes >

        <Route path="/" element={<Home />} />

      </Routes>




    </div>
  );
}

export default App;
