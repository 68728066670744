import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 360 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.7043 49.1776L17.2104 49.1776L17.2104 72.1932H38.405C42.1897 72.1932 45.1364 71.6831 47.2454 70.6628C51.0756 68.7876 52.9879 65.1996 52.9822 59.8988C52.9822 55.4131 51.1297 52.3267 47.4249 50.6395C45.3559 49.7048 42.449 49.2175 38.7043 49.1776ZM47.6557 32.728C49.9983 31.3259 51.1696 28.8094 51.1696 25.1787C51.1696 21.149 49.6079 18.4929 46.4844 17.2104C43.1422 16.2245 39.6654 15.7715 36.1821 15.8681L17.2104 15.8681L17.2104 34.8911L38.405 34.8911C42.1897 34.8911 45.2732 34.1701 47.6557 32.728ZM39.1061 87.2064H0L0 0.85495L41.8933 0.85495C52.472 1.00884 59.9672 4.07247 64.3789 10.0458C67.106 13.8979 68.5155 18.5282 68.3972 23.2465C68.3972 28.5131 67.072 32.748 64.4216 35.9513C62.5862 38.0117 60.3564 39.6833 57.864 40.8673C61.9478 42.2154 65.4611 44.8948 67.8415 48.4765C70.1766 52.2714 71.3477 56.6677 71.21 61.1214C71.2798 65.9842 69.9374 70.7627 67.3456 74.8778C65.6143 77.7454 63.2992 80.2169 60.5508 82.1317C57.8023 84.0465 54.6818 85.3619 51.3919 85.9923C47.3486 86.8135 43.232 87.2203 39.1061 87.2064Z"
      fill="#2A0537"
    />
    <path
      d="M94.6959 0.88063L78.007 0.88063L78.007 87.1808H94.6959L94.6959 0.88063Z"
      fill="#2A0537"
    />
    <path
      d="M120.439 75.1172C122.058 76.4283 124.094 77.114 126.176 77.0494C129.734 77.0611 133.206 75.9545 136.102 73.886C139.123 71.7828 140.693 67.9383 140.813 62.3525V56.1455C139.808 56.789 138.739 57.3276 137.624 57.7528C136.186 58.2606 134.707 58.6439 133.204 58.8985L129.45 59.5996C125.939 60.2265 123.423 60.9874 121.901 61.8823C120.663 62.5898 119.647 63.6272 118.965 64.8787C118.282 66.1301 117.961 67.5466 118.036 68.97C118.036 71.817 118.832 73.8689 120.439 75.1172ZM108.025 30.2657C112.436 24.6458 120.009 21.8358 130.741 21.8358C137.191 21.7145 143.576 23.1393 149.362 25.9909C154.783 28.7724 157.493 34.0048 157.493 41.6881V70.962C157.493 72.9912 157.533 75.4506 157.613 78.3404C157.732 80.5205 158.066 82.0082 158.613 82.7862C159.243 83.639 160.09 84.3065 161.067 84.7184V87.2064L142.916 87.2064C142.446 86.0331 142.093 84.8159 141.864 83.5728C141.668 82.4442 141.514 81.1532 141.394 79.7083C139.075 82.2203 136.392 84.3702 133.434 86.0864C129.725 88.1864 125.522 89.2578 121.26 89.1899C115.48 89.1899 110.707 87.5426 106.939 84.2482C103.171 80.9537 101.288 76.2799 101.288 70.2268C101.288 62.3782 104.314 56.6984 110.367 53.1873C113.685 51.2722 118.564 49.9071 125.004 49.092L130.681 48.3824C132.942 48.1906 135.168 47.699 137.299 46.9204C139.676 45.903 140.873 44.3555 140.873 42.1754C140.873 39.6105 139.958 37.7552 138.145 36.7635C136.333 35.7717 133.665 35.2673 130.151 35.2673C126.213 35.2673 123.423 36.2448 121.781 38.1998C120.5 39.9127 119.692 41.9324 119.439 44.0563H103.34C103.693 38.4705 105.255 33.8822 108.025 30.2914"
      fill="#2A0537"
    />
    <path
      d="M226.446 87.1807L206.303 87.1807L191.025 59.8988L184.117 67.0976V87.1807H167.718V1.1713L184.117 1.1713V47.6557L204.901 23.9476L225.565 23.9476L203.259 48.0661L226.446 87.1807Z"
      fill="#2A0537"
    />
    <path
      d="M257.789 87.2064H241.16V35.7119H231.849V23.939H240.989V19.8352C240.989 13.0468 242.491 7.99392 245.495 4.67666C248.191 1.55889 253.674 0 261.944 0C262.962 0 263.996 -5.22339e-07 265.048 0.119695L268.331 0.350535V13.9359C267.544 13.9359 266.783 13.8675 266.048 13.8419C265.313 13.8162 264.406 13.8419 263.346 13.8419C260.781 13.8419 259.131 14.5686 258.55 16.0049C257.969 17.4413 257.695 19.7155 257.789 22.8447V23.939L268.741 23.939V35.7119H257.789V87.2064Z"
      fill="#2A0537"
    />
    <path
      d="M291.705 0.350571L275.367 0.350571V15.5775L291.705 15.5775V0.350571Z"
      fill="#2A0537"
    />
    <path
      d="M292.005 23.6569L275.376 23.6569V87.1808H292.005V23.6569Z"
      fill="#2A0537"
    />
    <path
      d="M317.491 65.3962C319.771 70.7825 323.832 73.4757 329.674 73.4757C331.545 73.5013 333.399 73.1192 335.108 72.3559C336.816 71.5925 338.337 70.4663 339.566 69.0555C342.257 66.1144 343.602 61.4207 343.602 54.9742C343.602 48.9325 342.325 44.3271 339.772 41.158C338.553 39.6187 336.989 38.3874 335.207 37.5632C333.425 36.739 331.474 36.345 329.512 36.4129C323.658 36.4129 319.62 39.1659 317.397 44.6719C316.132 48.1393 315.535 51.8147 315.636 55.5043C315.575 58.8952 316.212 62.2624 317.508 65.3962M320.321 98.3637C322.117 99.8855 325.14 100.646 329.392 100.646C335.4 100.646 339.421 98.6344 341.456 94.6104C342.784 92.0398 343.448 87.708 343.448 81.6149V77.5196C342.162 79.8881 340.418 81.9776 338.318 83.6668C335.001 86.2032 330.689 87.4714 325.382 87.4714C317.186 87.4714 310.631 84.5931 305.718 78.8363C300.805 73.0795 298.36 65.2822 298.383 55.4444C298.383 45.9657 300.745 37.9861 305.47 31.5054C310.195 25.0248 316.887 21.793 325.545 21.8101C328.406 21.7486 331.252 22.2452 333.924 23.2721C337.976 24.982 341.253 28.0656 343.756 32.5228V23.3576L360 23.3576V83.8977C360 92.1281 358.615 98.3352 355.845 102.519C351.08 109.701 341.946 113.291 328.443 113.291C320.287 113.291 313.632 111.69 308.48 108.486C303.327 105.283 300.477 100.504 299.93 94.1487H318.047C318.33 95.7642 319.132 97.2436 320.33 98.3637"
      fill="#2A0537"
    />
  </svg>
);
export default SVGComponent;
